import { ExternalSystemFieldDataType } from './enums'

export enum Icon {
  Gate = 'GATE',
  Door = 'DOOR',
  Lock = 'LOCK',
  Garage = 'GARAGE',
  Handle = 'HANDLE',
  Barrier = 'BARRIER',
  Lift = 'LIFT',
  Speedgate = 'SPEEDGATE',
}

export interface PageInfo {
  endCursor: string
  hasNextPage: boolean
  hasPreviousPage: boolean
}

export interface Lock {
  id: string
  icon: Icon
  name: string
  description: string
  options: {
    nodes: {
      id: string
      title: string
      displayValue: string
      sortOrder: number
    }[]
  }
  location: {
    name: string
  }
}

export interface AccessProfile {
  id: string
  name: string
}

export interface User {
  id: string
  email: string | null
  name: string | null
}

export interface AccessCode {
  id: string
  name: string | null
  code: string
  viewerCanRemove: boolean
  dateTimeRange?: {
    start: string
    end: string
  }
}

export interface ExternalSystemField {
  value: string | null
  node: {
    id: string
    title: string
    summary: string
    dataType: ExternalSystemFieldDataType
    regexPattern: string
  }
}

export interface AccessCustomCredentialsField {
  value: string | null
  node: {
    id: string
    title: string
    summary: string
  }
}
